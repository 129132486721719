<template>
  <div class="app-container">
    <div class="top_box">
      <input type="file" @change="handleFileUpload" accept="image/*" />
    </div>
    <div class="canvas-bg">
      <div class="img-i-btn">
        <i :class="{'el-icon-rank':true,'actionActiveColor':canvasAction=='drag'?true:false}" @click="dragCanvas"></i>
        <i :class="{'el-icon-zoom-in':true,'actionActiveColor':canvasAction=='zoomIn'?true:false}" @click="zoomInCanvas"></i>
        <i :class="{'el-icon-zoom-out':true,'actionActiveColor':canvasAction=='zoomOut'?true:false}" @click="zoomOutCanvas"></i>
        <i :class="{'el-icon-refresh-left':true,'actionActiveColor':canvasAction=='rotateLeft'?true:false}" @click="rotateLeft"></i>
        <i :class="{'el-icon-refresh-right':true,'actionActiveColor':canvasAction=='rotateRight'?true:false}" @click="rotateRight"></i>
        <i :class="{'el-icon-crop':true,'actionActiveColor':canvasAction=='selecting_Rect'?true:false}" @click="zoomSelectingRect"></i>
        <i :class="{'element-icons':true,'el-icon-yuanxing':true,'actionActiveColor':canvasAction=='selecting_Circle'?true:false}" @click="zoomSelectingCircle"></i>
        <i :class="{'el-icon-thumb':true,'actionActiveColor':canvasAction=='eraser'?true:false}"  @click="eraserCanvas"></i>
      </div>
      <canvas ref="canvas"></canvas>
    </div>
    <el-input
      style="width: 100%; margin: 10px 0"
      size="small"
      v-model="prompt"
      placeholder="请输入生成要求，例：请在图片空白区域加一朵花"
    ></el-input>
    <div style="text-align: center; padding: 10px 0">
      <el-button size="small" type="primary" v-preventReClick @click="saveCanvas(0)"
        >生成</el-button
      >
      <el-button size="small" type="primary" v-preventReClick @click="saveCanvas(1)"
        >原图变异</el-button
      >
      <el-button size="small" @click="resetImage" v-preventReClick>重置</el-button>
    </div>
    <div class="tips">
        所有生成的图片由人工智能模型自动生成，其准确性和完整性不能保证，也不代表我们的态度和观点。
      </div>
    <div class="showarea">
      <el-row
        v-if="imgData.length < 1"
        v-loading="loading"
        element-loading-text="拼命加载中..."
        style="margin-top: 100px"
      ></el-row>
      <div v-else>
        <p style="padding-bottom: 10px; text-align: center; color: #1890ff">
          以下是{{ saveCanvasShow == 0 ? '新生成的' : '变异后的' }}图片
        </p>
        <div class="img-box-list">
          <el-image
            v-for="item in imgData"
            :key="item"
            :src="item"
            :preview-src-list="imgData || []"
          ></el-image>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// globalCompositeOperation是Canvas API中的一个属性，可以用来设置绘制内容的混合模式。它可以控制在绘制新的图形时，如何将颜色与之前已绘制的颜色混合。

// 该属性接受一个字符串值，指定一个混合模式。常见的值包括：

// source-over（默认值）：新绘制的图形将覆盖掉之前已绘制的图形
// source-in：只有新绘制的图形重叠的区域会被保留，其他区域会被清除
// source-out：只有新绘制的图形不重叠的区域会被保留，其他区域会被清除
// source-atop：只有新绘制的图形与之前已绘制的图形重叠的区域会被保留，新绘制的图形上面的区域会被透明处理，之前已绘制的图形下面的区域不受影响
// destination-over：新绘制的图形会被放置到之前已绘制的图形下面
// destination-in：只有之前已绘制的图形与新绘制的图形重叠的区域会被保留，其他区域会被清除
// destination-out：只有之前已绘制的图形不重叠的区域会被保留，其他区域会被清除
// destination-atop：只有之前已绘制的图形与新绘制的图形重叠的区域会被保留，之前已绘制的图形上面的区域会被透明处理，新绘制的图形下面的区域不受影响
// lighter：将新绘制的图形与之前已绘制的图形叠加起来，得到更亮的颜色
// darker：将新绘制的图形与之前已绘制的图形叠加起来，得到更暗的颜色
// xor：新绘制的图形与之前已绘制的图形重叠的区域会被清除，其他区域会被保留，得到一种类似异或运算的效果
import { editImages, variationImages } from '@/api/ai/image'

export default {
  name: 'AiChat',
  data() {
    return {
      attachments: [],
      imgData: [],
      pageType: 'ocr',
      uploadFile: null, //上传控件选择的图片
      canvas: null, //画布
      ctx: null, //画布上下文对象
      image: {}, //  图片对象
      maskData: null, //蒙版层
      angle: 0, //旋转角度
      dragStartX: 0,
      dragStartY: 0,
      dImageX: 0, // 目标图片的X坐标
      dImageY: 0, // 目标图片的Y坐标,
      initialTransform: null,
      loading: false,
      prompt: '',
      touchOffSet: {
        left: undefined,
        top: undefined
      },
      canvasSelect:{
        startX:null,
        startY:null,
        endX:null,
        endY:null
      },//圈选坐标
      canvasAction:null,//drag 拖拽 zoomIn 放大 zoomOut缩小 rotateLeft 左转 rotateRight 右转  selecting_Rect 矩形圈选  eraser 橡皮擦划线 
      canvasDrawRadius:null,//圆形
      canvasDrawYx:{
        centerX:null,
        centerY:null,
      },//圆形中心点
      saveCanvasShow: 0 // 0生成 1变异
    }
  },
  mounted() {
    this.initializeCanvas()
  },
  methods: {
    // 初始化图片
    initializeCanvas() {
      this.canvas = this.$refs.canvas
      this.canvas.width = 256
      this.canvas.height = 256
      this.ctx = this.canvas.getContext('2d')
      this.initialTransform = this.ctx.getTransform()
      const rect = this.canvas.getBoundingClientRect()
      this.touchOffSet.left = rect.left + window.pageXOffset
      this.touchOffSet.top = rect.top + window.pageYOffset
      // 添加鼠标事件监听器
      this.canvas.addEventListener('mousedown', this.startMouseDrawing)
      this.canvas.addEventListener('mousemove', this.mouseDraw)
      this.canvas.addEventListener('mouseup', this.stopMouseDrawing)
      this.canvas.addEventListener('mouseout', this.stopMouseDrawing)
      this.canvas.addEventListener('touchstart', this.touchStartDrawing)
      this.canvas.addEventListener('touchmove', this.touchMoveDrawing)
      this.canvas.addEventListener('touchend', this.touchEndDrawing)
    },
    // 上传控件选择图片
    handleFileUpload(event) {
      // 读取上传的图片文件
      this.uploadFile = event.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(this.uploadFile)
      reader.onload = () => {
        // 创建图片对象并保存
        // 加载图片到画布
        this.image = new Image()
        this.image.src = reader.result
        this.image.onload = () => {
          this.ctx.globalCompositeOperation = 'source-over'
          let maxNum = Math.max(this.image.width, this.image.height)
          let scala = Math.ceil(maxNum / this.canvas.width)
          console.log('scala:' + scala)
          this.image.width /= scala
          this.image.height /= scala
          this.dImageX = (this.canvas.width - this.image.width) / 2
          this.dImageY = (this.canvas.height - this.image.height) / 2
          this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
          this.ctx.drawImage(
            this.image,
            this.dImageX,
            this.dImageY,
            this.image.width,
            this.image.height
          )
          
        }
        this.canvasAction='drag';
      }
    },
    // 拖动
    dragCanvas() {
      if (!this.uploadFile) return
      this.canvasAction='drag';
    },
    // 橡皮擦
    eraserCanvas() {
      if (!this.uploadFile) return
      this.canvasAction='eraser';
    },
    // 矩形圈选
    zoomSelectingRect(){
      if (!this.uploadFile) return;
      this.canvasAction='selecting_Rect';
    },
    zoomSelectingCircle(){
       if (!this.uploadFile) return;
      this.canvasAction='selecting_Circle';
    },
    //左转
    rotateLeft() {
      if (!this.uploadFile) return;
      this.canvasAction='rotateLeft';
      this.angle -= 90;
      this.rotateDraw();
       
    },
    // 右转
    rotateRight() {
      if (!this.uploadFile) return;
      this.canvasAction='rotateRight';
      this.angle += 90;
      this.rotateDraw();
    },
    // 图像放大，非画布放大
    zoomInCanvas() {
      if (!this.uploadFile) return
      this.canvasAction='zoomIn';
      this.image.width *= 1.2
      this.image.height *= 1.2
      this.zoomCanvas();
    },
    // 图像缩小，非画布缩小
    zoomOutCanvas() {
      if (!this.uploadFile) return
      this.canvasAction='zoomOut';
      this.image.width /= 1.2
      this.image.height /= 1.2
      this.zoomCanvas();
    },
     // 重置图像操作
    resetImage() {
      this.prompt = ''
      if (!this.uploadFile) return
      this.ctx.setTransform(this.initialTransform);
      this.ctx.globalCompositeOperation = 'source-over'
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
      const reader = new FileReader()
      reader.readAsDataURL(this.uploadFile)
      reader.onload = () => {
        // 创建图片对象并保存
        // 加载图片到画布
        this.image = new Image()
        this.image.src = reader.result
        this.image.onload = () => {
          let maxNum = Math.max(this.image.width, this.image.height)
          let scala = Math.ceil(maxNum / this.canvas.width)
          console.log('scala:' + scala)
          this.image.width /= scala
          this.image.height /= scala
          this.dImageX = (this.canvas.width - this.image.width) / 2
          this.dImageY = (this.canvas.height - this.image.height) / 2
          this.ctx.drawImage(
            this.image,
            this.dImageX,
            this.dImageY,
            this.image.width,
            this.image.height
          )
          
        }
      }
      this.canvasAction='drag';
    },
    // 鼠标拖动开始
    startMouseDrawing(event) {
      if (!this.uploadFile) return
      this.isDrawing = true
      this.ctx.globalCompositeOperation = 'source-over'
      switch(this.canvasAction){
        case 'drag':
          this.dragStartX = event.clientX
          this.dragStartY = event.clientY
          this.canvas.style.cursor = 'grabbing'
          console.log('startDrawing isDrag')
          break;
        case 'eraser':
          this.ctx.beginPath()
          this.ctx.moveTo(event.offsetX, event.offsetY)
          console.log('startDrawing isEraser')
          break;
        case 'selecting_Rect':
          this.canvasSelect.startX=event.offsetX;
          this.canvasSelect.startY=event.offsetY;
          break;
        case 'selecting_Circle':
          this.canvasSelect.startX=event.offsetX;
          this.canvasSelect.startY=event.offsetY;
          this.canvasDrawRadius=null;
          break;          
      }
    },
    // 鼠标拖动
    mouseDraw(event) {
      if (!this.isDrawing) return;
      const rect = this.canvas.getBoundingClientRect();
      switch(this.canvasAction){
        case 'drag':// 拖动图片
          console.log('draw isDrag');
          this.ctx.globalCompositeOperation = 'source-over'
          const deltaX = event.clientX - this.dragStartX
          const deltaY = event.clientY - this.dragStartY
          this.dImageX += deltaX
          this.dImageY += deltaY
          this.dragStartX = event.clientX
          this.dragStartY = event.clientY
          this.canvas.style.cursor = 'grabbing'
          this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
          this.ctx.drawImage(
            this.image,
            this.dImageX,
            this.dImageY,
            this.image.width,
            this.image.height
          )
          break;
        case 'eraser'://随意画
          console.log('draw isEraser');
          this.ctx.lineTo(event.offsetX, event.offsetY)
          this.ctx.strokeStyle = '#FFFFFF' // 设置橡皮擦颜色为白色
          this.ctx.lineWidth = 10 // 设置橡皮擦宽度
          this.ctx.lineCap = 'round'
          this.ctx.lineJoin = 'round'
          this.ctx.globalCompositeOperation = 'destination-out' // 只显示已有图形不与新图形重叠的部分（剩下的部分都会被清除）使用destination-out模式实现橡皮擦效果
          this.ctx.stroke()
          break;
        case 'selecting_Rect'://画矩形
          console.log('draw selecting_Rect');
          this.canvasSelect.endX = event.clientX - rect.left;
          this.canvasSelect.endY = event.clientY - rect.top;

          this.ctx.fillStyle = 'rgba(0, 0, 0, 0.2)';
          this.ctx.clearRect(this.canvasSelect.startX, this.canvasSelect.startY, this.canvasSelect.endX - this.canvasSelect.startX, this.canvasSelect.endY - this.canvasSelect.startY);
         // this.ctx.fillRect(this.canvasSelect.startX, this.canvasSelect.startY, this.canvasSelect.endX - this.canvasSelect.startX, this.canvasSelect.endY - this.canvasSelect.startY);
          break;
        case 'selecting_Circle'://画圆
          console.log('draw selecting_Circle');
          
          this.canvasSelect.endX = event.clientX - rect.left;
          this.canvasSelect.endY = event.clientY - rect.top;
          this.updateRadiusAndCenter();
          // this.ctx.beginPath()
          this.ctx.arc(this.canvasDrawYx.centerX, this.canvasDrawYx.centerY, this.canvasDrawRadius, 0, 2 * Math.PI);
          this.ctx.globalCompositeOperation = 'destination-out' // 只显示已有图形不与新图形重叠的部分（剩下的部分都会被清除）使用destination-out模式实现橡皮擦效果
          this.ctx.stroke();
          this.ctx.fill();
          break;
      }
    },
    // 鼠标拖动结束
    stopMouseDrawing() {
      this.isDrawing = false
      console.log('stopMouseDrawing:'+this.canvasAction)
    },
    // 手指触摸开始
    touchStartDrawing(event) {
      if (!this.uploadFile) return
      event.preventDefault()
      this.isDrawing = true
      const touch = event.touches[0] // 获取第一个触摸点
      this.ctx.globalCompositeOperation = 'source-over'
      switch(this.canvasAction){
        case 'drag':
          this.dragStartX = touch.clientX;
          this.dragStartY = touch.clientY;
          this.canvas.style.cursor = 'grabbing';
          break;
        case 'eraser':
          this.ctx.beginPath()
          this.ctx.moveTo(
            touch.clientX - this.touchOffSet.left,
            touch.clientY - this.touchOffSet.top
          )
          break;
        case 'selecting_Rect':
          this.canvasSelect.startX=touch.clientX - this.touchOffSet.left;
          this.canvasSelect.startY=touch.clientY - this.touchOffSet.top;
          break;
        case 'selecting_Circle':
          this.canvasSelect.startX=touch.clientX - this.touchOffSet.left;
          this.canvasSelect.startY=touch.clientY - this.touchOffSet.top;
          break;
      }
    },
    // 手指触摸移动
    touchMoveDrawing(event) {
      if (!this.isDrawing) return;
      event.preventDefault();
      const touch = event.touches[0]; // 获取第一个触摸点
      switch(this.canvasAction){
        case 'drag':// 拖动图片
          this.ctx.globalCompositeOperation = 'source-over'
          const deltaX = touch.clientX - this.dragStartX
          const deltaY = touch.clientY - this.dragStartY
          this.dImageX += deltaX
          this.dImageY += deltaY
          this.dragStartX = touch.clientX
          this.dragStartY = touch.clientY
          this.canvas.style.cursor = 'grabbing'
          this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
          this.ctx.drawImage(
            this.image,
            this.dImageX,
            this.dImageY,
            this.image.width,
            this.image.height
          )
          break;
        case 'eraser'://随意画
          this.ctx.lineTo(
          touch.clientX - this.touchOffSet.left,
          touch.clientY - this.touchOffSet.top
          )
          this.ctx.strokeStyle = '#FFFFFF' // 设置橡皮擦颜色为白色
          this.ctx.lineWidth = 10 // 设置橡皮擦宽度
          this.ctx.lineCap = 'round'
          this.ctx.lineJoin = 'round'
          this.ctx.globalCompositeOperation = 'destination-out' // 只显示已有图形不与新图形重叠的部分（剩下的部分都会被清除）使用destination-out模式实现橡皮擦效果
          this.ctx.stroke()
          break;
        case 'selecting_Rect'://画矩形
          console.log('touch draw selecting_Rect');
          const rect = this.canvas.getBoundingClientRect();
          this.canvasSelect.endX = touch.clientX - this.touchOffSet.left;
          this.canvasSelect.endY = touch.clientY - this.touchOffSet.top;
          let width=Math.abs(this.canvasSelect.endX-this.canvasSelect.startX);
          let height=Math.abs(this.canvasSelect.endY-this.canvasSelect.startY);
          this.ctx.fillStyle = 'rgba(0, 0, 0, 0.2)';
          this.ctx.clearRect(this.canvasSelect.startX, this.canvasSelect.startY, width, height);
          break;
        case 'selecting_Circle'://画圆
          console.log('touch draw selecting_Circle');
          this.canvasSelect.endX = touch.clientX - this.touchOffSet.left;
          this.canvasSelect.endY = touch.clientY - this.touchOffSet.top;
          this.updateRadiusAndCenter();
          // this.ctx.beginPath()
          this.ctx.arc(this.canvasDrawYx.centerX, this.canvasDrawYx.centerY, this.canvasDrawRadius, 0, 2 * Math.PI);
          this.ctx.globalCompositeOperation = 'destination-out' // 只显示已有图形不与新图形重叠的部分（剩下的部分都会被清除）使用destination-out模式实现橡皮擦效果
          this.ctx.stroke();
          this.ctx.fill();
          break;
      }
    },
    // 手指触摸离开
    touchEndDrawing(event) {
      this.isDrawing = false
    },
    //更新中心点坐标
    updateRadiusAndCenter() {
      this.canvasDrawRadius = Math.sqrt(
        Math.pow(this.canvasSelect.endX - this.canvasSelect.startX, 2) + Math.pow(this.canvasSelect.endY - this.canvasSelect.startY, 2)
      );
      this.canvasDrawYx.centerX = (this.canvasSelect.startX + this.canvasSelect.endX) / 2;
      this.canvasDrawYx.centerY = (this.canvasSelect.startY + this.canvasSelect.endY) / 2;
    },
    // 放大缩小公共函数
    zoomCanvas(){
      this.ctx.globalCompositeOperation = 'source-over'
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
      this.dImageX = (this.canvas.width - this.image.width) / 2
      this.dImageY = (this.canvas.height - this.image.height) / 2
      this.ctx.drawImage(
        this.image,
        this.dImageX,
        this.dImageY,
        this.image.width,
        this.image.height
      )
    },
    //旋转图片主函数
    rotateDraw() {
      this.ctx.globalCompositeOperation = 'source-over'
      let degree = 0
      degree += parseInt(this.angle)
      degree %= 360
      this.ctx.save()
      // 清空画布
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
      // 计算旋转后图片的位置
      const centerX = this.canvas.width / 2
      const centerY = this.canvas.height / 2
      const imgX = centerX - this.image.width / 2
      const imgY = centerY - this.image.height / 2
      // 将坐标系原点移动到旋转中心
      this.ctx.translate(centerX, centerY)
      // 旋转图片
      this.ctx.rotate((degree / 180) * Math.PI)
      // 恢复坐标系原点
      this.ctx.translate(-centerX, -centerY)
      // 将图片绘制在旋转中心
      this.ctx.drawImage(this.image, imgX, imgY,this.image.width,this.image.height);
      this.ctx.restore();
      // let dataRotateImage=this.canvas.toDataURL('image/png');
      // this.image.src=dataRotateImage;
      
    },
    // 保存画布
    saveCanvas(v) {
      console.log(1)
      if (!this.uploadFile) {
        this.$msgError('请上传图片')
        return
      }
      const dataURL = this.canvas.toDataURL('image/png')
      if (v == 0) {
        // 生成图片
        if (!this.prompt) {
          this.$msgError('请输入生成图片要求')
          return
        }
        this.imgData = []
        this.saveCanvasShow = 0
        this.getEditImages(dataURL)
      } else {
        // 图片变异
        this.imgData = []
        this.saveCanvasShow = 1
        this.getVariationImages(dataURL)
      }
    },
    getEditImages(dataURL) {
      // 生成图片
      if(this.loading)return;
      this.loading = true
      editImages({
        image: dataURL,
        mask: '', //   可以为空
        prompt: this.prompt, // 生成图片的提示
        n: 4, // 生成图片数量
        size: '256x256',
        response_format: 'b64_json'
      }).then((res) => {
        if (res.data.success) {
          this.imgData = res.data.result.map((e) => {
            return 'data:image/png;base64,' + e
          })
        } else {
          this.$msgError(res.data.message)
        }
        this.loading = false
      })
    },
    getVariationImages(dataURL) {
      // 图片变异
      if(this.loading)return;
      this.loading = true
      variationImages({
        image: dataURL,
        n: 4, // 变异图片数量
        size: '256x256',
        response_format: 'b64_json'
      }).then((res) => {
        if (res.data.success) {
          this.imgData = res.data.result.map((e) => {
            return 'data:image/png;base64,' + e
          })
        } else {
          this.$msgError(res.data.message)
        }
        this.loading = false
      })
    }
  }
}
</script>
<style scoped lang="scss">
@import '../../../assets/font_dld/iconfont.css';
.tips {
  text-align: center;
  margin: 0 0 15px 0;
  /* transform: scale(.5);
    transform-origin: center top; */
  /* color: #878aab; */
  color: #b4b9c3;
  font-size: 12px;
}
.el-icon-yuanxing{
    font-size: 24px;
    color: #333;
    background-size: 24px;
}
.actionActiveColor{
  color:#1890ff;
}
canvas {
  max-width: 100%;
  height: auto;
  background: url('../../../assets/img/msk.jpg');
  border: 1px solid #f8f8f8;
}

p {
  padding: 0;
  margin: 0;
}

.app-container {
  overflow: hidden;
  position: relative;
  overflow: hidden;
  height: 100%;
  padding: 10px;
}

.top_box {
  margin: 10px 0;
  border-bottom: 1px solid #dcdfe6;
  padding-bottom: 10px;
}

.showarea {
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  padding: 10px 0;
  line-height: 24px;
  border-top: 1px solid #dcdfe6;
  margin-top: 10px;

  .img-box {
    text-align: center;

    img {
      width: 256px;
      height: 256px;
    }
  }

  .img-box-list {
    width: 516px;
    margin: 0 auto;
    overflow: hidden;

    .el-image {
      width: 258px;
      float: left;
      padding: 1px;

      img {
        width: 100%;
      }
    }
  }
}

.canvas-bg {
  text-align: center;

  .img-i-btn {
    font-size: 24px;
    color: #333;

    i {
      margin: 10px;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 768px) {
  [class*='top_box'] {
    width: 100%;
    background: #fff;
  }

  [class*='showarea'] {
    width: 100%;
  }

  [class*='img-box-list'] {
    width: auto !important;

    .el-image {
      width: 50% !important;
    }
  }
}
</style>
