import { request, post, getStream } from '@/plugins/request';

/**
 * 图片生成
 * sample
 * data={
 *   "prompt":"一只小猫",
 *   "n":1,
 *   "size":"256x256",
 *   "response_format":"b64_json"
 *  }
 * -----------------------------------
 *  Comments:  
 *    prompt: 生成图片的提示   
 *        例如：现任总统照片
 *    n: 生成图片数量
 *        例如：1-10之间
 *    size: 生成图片尺寸
 *        例如：'256x256' or '512x512' or '1024x1024'
 *    response_format: 生成图片格式
 *        例如：url or b64_json 
 */
export function generationImages(data) {
    return post('/aichat/v0.1/chat/chat/ct/images/generation', data);
}
/**
 * 编辑图片生成
 * sample
 * data={
 *   "guid":"xx12321321312发生的",
 *   "mask_guid":"xx12321321312发生的",
 *   "prompt":"一只白色的猫在屋子里",
 *   "n":1,
 *   "size":"256x256",
 *   "response_format":"b64_json"
 *  }
 * -----------------------------------
 *  Comments:  
 *    image: base64
 *    mask: base64   可以为空
 *    prompt: 生成图片的提示   
 *        例如：现任总统照片
 *    n: 生成图片数量
 *        例如：1-10之间
 *    size: 生成图片尺寸
 *        例如：'256x256' or '512x512' or '1024x1024'
 *    response_format: 生成图片格式
 *        例如：url or b64_json 
 */
export function editImages(data) {
    return post('/aichat/v0.1/chat/chat/ct/images/edit', data);
}

/**
 * 图片变异
 * sample
 * data={
 *   "image":base64,
 *   "n":1,
 *   "size":"256x256",
 *   "response_format":"b64_json"
 *  }
 * -----------------------------------
 *  Comments:  
 *    guid: 原始图片的guid
 *    n: 生成图片数量
 *        例如：1-10之间
 *    size: 生成图片尺寸
 *        例如：'256x256' or '512x512' or '1024x1024'
 *    response_format: 生成图片格式
 *        例如：url or b64_json 
 */
export function variationImages(data) {
    return post('/aichat/v0.1/chat/chat/ct/images/variation', data);
}